import React from 'react'
import styled from 'styled-components'

import markerNote from '../graphics/map/marker_Note.png'
import markerTodo from '../graphics/map/marker_ToDo.png'
import markerWeather from '../graphics/map/marker_Weather.png'

export default function Marker(props) {
    return (
        <Container onClick={props.onClick} image={GetMarkerImage(props.type)} />
    )
}

const GetMarkerImage = id => {
    switch (id) {
        case 'note': return markerNote
        case 'todo': return markerTodo
        case 'weather': return markerWeather
    }
}

const Container = styled.div`
    position: absolute;
    transform: translate(-50%, -100%);

    width: 3rem;
    height: 3rem;

    background-image: url(${p => p.image});
    background-size: cover;
    background-repeat: no-repeat;

    :hover {
        width: 3.2rem;
        height: 3.2rem;
        cursor: pointer;
    }
`