import React from 'react'
import styled from 'styled-components'

import farmIcon from '../graphics/farmIcon.svg'

export default function Logo(props) {
    return (
        <Container style={props.style} light={props.light}/>
    )
}

const Container = styled.div`
    background-color: ${p => p.light ? p.theme.light : p.theme.primary};
    -webkit-mask-image: url(${farmIcon});
    mask-image: url(${farmIcon});
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
`