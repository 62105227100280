import React, { useContext } from 'react';
import './App.css';

import MapPage from './pages/MapPage'
import LoginOverlay from './pages/LoginOverlay'

import User from './context/UserContext'

function App() {
  const [user, setUser] = useContext(User)

  return (
    <>
    <MapPage />
    {!user && <LoginOverlay />}
    </>
  );
}

export default App;