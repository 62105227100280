import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import Button from '../components/Button'
import InputField from '../components/InputField'
import TextButton from '../components/TextButton'

import User from '../context/UserContext'

import farmIcon from '../graphics/farmIcon.svg'

export default function LoginOverlay(props) {
    const [user, setUser] = useContext(User)
    const [state, setState] = useState({
        user: '',
        pass: '',
    })

    const loginHandler = () => {
        if (state.user === 'admin' && state.pass === 'admin') setUser('admin')
    }

    const guestHandler = () => {
        setUser('admin')
    }

    return (
        <Container>
            <Panel>
                <Logo icon={farmIcon}/>
                <InputField 
                    height='2.2rem' 
                    placeholder='User'
                    value={state.user}
                    onValueChange={v => setState({...state, user: v})}
                    />
                <InputField 
                    type='password'
                    height='2.2rem' 
                    placeholder='Password'
                    value={state.pass}
                    onValueChange={v => setState({...state, pass: v})}
                    />
                <Button onClick={loginHandler} primary>Sign in</Button>
                <Button onClick={guestHandler} >Continue as a guest</Button>
                <TextButton style={{textAlign: 'center'}}>I forgot my password</TextButton>
            </Panel>
        </Container>
    )
}

const Logo = styled.div`
    background-color: ${p => p.theme.primary};
    -webkit-mask-image: url(${p => p.icon});
    mask-image: url(${p => p.icon});
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    width: 7rem;
    height: 7rem;
    margin: auto;
    margin-bottom: 1rem;
`

const Panel = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;

    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
`

const Container = styled.div`
    z-index: 1;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: ${p => p.theme.light};
`