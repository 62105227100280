import React from 'react'
import styled from 'styled-components'

export default function InfoCard(props) {
    return (
        <Container>
            <Title>{props.marker.Name}</Title>
            <Description>{props.marker.Description}</Description>
        </Container>
    )
}

const Container = styled.div`
    background-color: ${p => p.theme.primary};
    border-radius: .3rem;
    padding: 1rem;

    display: grid;
    grid-gap: 1rem;
`

const Title = styled.div`
    color: ${p => p.theme.light};
    font-weight: bold;
    font-size: 1rem;
`

const Description = styled.div`
    color: ${p => p.theme.light};
`