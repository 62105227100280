import { useState, useEffect } from 'react';

export function useSpreadSheet() {
    const [init, setInit] = useState(true)
    const [rows, setRows] = useState()
    const [state, setState] = useState()

    const getRows = async () => {
        setInit(false)
        let url = 'https://sheets.googleapis.com/v4/spreadsheets/1HRbJrvSCwrXV1wWbn4LL9JR9t0KHuFpXIGt6MAiW2X4?key=AIzaSyDawj77irT3Pzx06KNt55OUPw2inqCIlt0'
        let response = await fetch(url)
        let spreadsheet = await response.json()
        let skipTemplate = true

        const sheetUrl = id => `https://sheets.googleapis.com/v4/spreadsheets/1HRbJrvSCwrXV1wWbn4LL9JR9t0KHuFpXIGt6MAiW2X4/values/${id}?key=AIzaSyDawj77irT3Pzx06KNt55OUPw2inqCIlt0`

        let state = {}

        for (let sheet of spreadsheet.sheets) {
            if (skipTemplate) {
                skipTemplate = false
                continue
            }

            response = await fetch(sheetUrl(sheet.properties.title))
            let rows = await response.json()

            let firstRow = null
            let tempSheet = {}
            tempSheet.items = []

            if (rows.values) for (let row of rows.values) {
                if (!firstRow) {
                    firstRow = row
                    continue
                }

                let tempItem = {}
                let i = 0
                for (let value of row) tempItem[firstRow[i++]] = value

                tempSheet.items.push(tempItem)
            }

            state[sheet.properties.title] = tempSheet
        }

        setState(state)
    }

    if (init) getRows() 

    return state
}