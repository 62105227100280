import React, { useRef } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

const Container = styled.div`
    height: 100%;
    width: 100%;
`

export default function Map(props) {
    const map = useRef()
    const mapConfig = {
        center: props.center ? props.center : {
            lat: -35.009996,
            lng: 148.601852
        },
        zoom: 14 
    }

    const options = {
        mapTypeId: 'satellite',
        zoomControl: true,
        zoomControlOptions: {
            position: 6
        }
    }

    if (map.current) map.current.map_.zoom = mapConfig.zoom

    return (
        <Container>
            <GoogleMapReact
                ref={map}
                bootstrapURLKeys={{ key: 'AIzaSyBv7A63xiHVHiuEdOHHfWMUTxAh1LxDxi4' }}
                center={mapConfig.center}
                zoom={mapConfig.zoom}
                options={options}
                >
                    {props.children}
            </GoogleMapReact>
        </Container>
    )
}