import React, { useState } from 'react'
import styled from 'styled-components'

import InfoCard from '../components/InfoCard'
import IconButton from '../components/IconButton'
import Logo from '../components/Logo'

export default function MapOverlay(props) {
    const [state, setState] = useState({
        menuState: true,
    })

    const farmClickHanlder = f => {
        setState({...state, menuState: false})
        props.onFarmClick(f)
    }

    const buttons = props.farms ? Object.entries(props.farms).map(([key, value]) => 
        <GetFarmButton key={key} onClick={() => farmClickHanlder(value)}>{key}</GetFarmButton>) : []

    return (
        <Container>
            {props.selectedMarker && <InfoPanel><InfoCard marker={props.selectedMarker}/></InfoPanel>}
            {state.menuState && <GetMenuPanel farmList={buttons}/>}
            <MenuButton><IconButton onClick={() => setState({...state, menuState: !state.menuState})} icon='menu'/></MenuButton>
        </Container>
    )
}

function GetMenuPanel(props) {
    return (
        <MenuPanel>
            <MenuTitle>
                <Logo light/>
                <Title>Farms</Title>
            </MenuTitle>
            <FarmList>{props.farmList}</FarmList>
        </MenuPanel>
    )
}

function GetFarmButton(props) {
    return (
       <FarmButton onClick={props.onClick}>
           <Logo/>
           <Text>{props.children}</Text>
       </FarmButton> 
    )
}

const Text = styled.div`
    color: ${p => p.theme.primary};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const Title = styled.div`
    color: ${p => p.theme.light};
    font-weight: bold;
`

const MenuButton = styled.div`
    margin: .6rem;
    width: 2.5rem;
    height: 2.5rem;
`

const InfoPanel = styled.div`
    position: absolute;
    top: .7rem;
    right: 4rem;
    width: 20rem;
`

const MenuPanel = styled.div`
    overflow: hidden;
    border-radius: ${p => p.theme.roundCorners};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: grid;
    grid-template-rows: auto auto;

    width: 20rem;
`

const MenuTitle = styled.div`
    background-color: ${p => p.theme.primary};
    display: grid;
    padding: .5rem;
    grid-template-columns: 2rem auto;
    grid-gap: 1rem;
`

const FarmList = styled.div`
    background-color: ${p => p.theme.light};
    padding: 1rem;
`

const FarmButton = styled.div`
    display: grid;
    padding: .5rem;
    grid-template-columns: 2rem auto;
    grid-gap: 1rem;
    cursor: pointer;
    :hover {
        transform: translateY(-2%);
    }
`

const Container = styled.div`
    z-index: 1;
    position: fixed;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    * {
        pointer-events: all;
    }
`