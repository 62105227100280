import React, { useState } from 'react'
import styled from 'styled-components'

import { useSpreadSheet } from '../hooks/Hooks'

import Map from '../components/Map'
import Marker from '../components/Marker'

import MapOverlay from './MapOverlay'

export default function MapPage() {
    const [selectedFarm, setSelectedFarm] = useState()
    const [selectedMarker, setSelectedMarker] = useState()
    const state = useSpreadSheet()

    let markers = []
    let center = null

    if (state) {
        if (!selectedFarm) setSelectedFarm(state[Object.keys(state)[0]])
        if (selectedFarm) markers = selectedFarm.items
    }

    if (markers.length > 0) center = {
        lat: parseFloat(markers[0].Latitude),
        lng: parseFloat(markers[0].Longitude)
    }

    const farmClickHandler = f => {
        setSelectedMarker()
        setSelectedFarm(f)
    }

    const markerClickHandler = m => {
        if (m === selectedMarker) setSelectedMarker()
        else setSelectedMarker(m)
    }

    return (
        <Container>
            <MapOverlay 
                selectedMarker={selectedMarker}
                farms={state}
                onFarmClick={farmClickHandler}
                />
            <Map center={center}>
                {GetMarkers(markers, markerClickHandler)}
            </Map>
        </Container>
    )
}

const Container = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
`

const GetMarkers = (markers, clickHandler) => markers.map((m, i) => <Marker 
    onClick={() => clickHandler(m)}
    key={i}
    lat={m.Latitude}
    lng={m.Longitude}
    type={m.Type}
/>)