const Theme = {
    primary: '#63a87e',
    primaryHover: '#79ce9a',
    light: '#ffffff',
    dark: '#333333',
    medium: '#333333',

    roundCorners: '.5rem',
    rounderCorners: '1rem',
}

export default Theme