import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Theme.
import { ThemeProvider } from 'styled-components'
import Theme from './theme'

// Context.
import { UserContext } from './context/UserContext'

ReactDOM.render(
  <ThemeProvider theme= { Theme }>
    <UserContext>
      <App />
    </UserContext>
  </ThemeProvider>,
  document.getElementById('root')
);